<template>
  <div class="search-form">
    <el-form ref="form"
             :model="form"
             label-width="160px"
             label-position="right">
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="链上账户">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.publicKey"
                      clearable
                      placeholder="请输入链上账户"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="记账哈希">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.token"
                      clearable
                      placeholder="请输入记账哈希"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="记账类型">
            <el-select class="inputWid"
                       @keyup.enter.native="confirm"
                       v-model="form.type">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="width:100%">
        <el-col class="search-btn"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24">
          <el-form-item>
            <el-button type="primary"
                       @click="confirm">查询</el-button>
            <el-button class="form-item-Left"
                       @click="rest">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: [
        {
          value: null,
          label: '全部'
        },
        {
          value: 'Tokens',
          label: '记账单位'
        },
        {
          value: 'Deposit',
          label: '质押中的记账单位'
        }
      ]
    }
  },
  mounted() {},
  methods: {
    rest() {
      this.form.publicKey = ''
      this.form.token = ''
      this.form.type = null
      this.confirm()
    },
    confirm() {
      let _form = {
        publicKey: this.form.publicKey ? this.trim(this.form.publicKey) : '',
        token: this.form.token ? this.trim(this.form.token) : '',
        type: this.form.type
      }
      this.$emit('search', _form)
    },
    trim(s) {
      // 去除目标字符串左右两边的空白字符
      return s.replace(/(^\s*)|(\s*$)/g, '')
    }
  }
}
</script>
