<template>
  <div>
    <table-list :columns="columns"
                :data="list"
                :tableOption="{size: 'mini'}"
                :loading="loading"
                fit
                stripe
                :pageData="pageData"
                :headData="headData"
                @handlePageChange="handlePageChange"
                @handleSizeChange="handleSizeChange">

      <!-- 链上账户 -->
      <template #publicKey="{row}">
        <div class="div-row"
             v-if="row.publicKey">
          <popover :width="500"
                   :shard="shardName"
                   :value="row.publicKey"
                   :isSubStr="false"
                   :divClass="'shenglue-1'"></popover>
          <i class="el-icon-document-copy"
             style="margin-left:10px;"
             @click="copy($event, row.publicKey)"></i>
        </div>
        <span v-else-if="!row.publicKey">无</span>
      </template>

      <!-- 记账单位 -->
      <template #token="{row}">
        <div class="div-row"
             v-if="row.token">
          <popover :width="500"
                   :shard="shardName"
                   :value="row.token"
                   :isSubStr="false"
                   :divClass="'shenglue-1'"></popover>
          <i class="el-icon-document-copy"
             style="margin-left:10px;"
             @click="copy($event, row.token)"></i>
        </div>
        <span v-else>无</span>
      </template>

      <!-- 记账类型 -->
      <template #type="{row}">
        <span v-if="row.type==='Tokens'">记账单位</span>
        <span v-else-if="row.type==='Deposit'">质押中的记账单位</span>
        <span v-else>无</span>
      </template>

      <!-- 记账数量 -->
      <template #value="{row}">
        <span>{{ toThousands(row.value) }}</span>
      </template>

      <!-- 查看按钮 -->
      <!-- <template #row="{row}">
        <el-button type="text"
                   @click="handleDetail(row.publicKey)">查看</el-button>
      </template> -->

    </table-list>
    <!-- Dialog -->
    <el-dialog :title="title"
               width="55%"
               :visible.sync="dialogVisible">
      <pre v-if="info"
           v-html="info"></pre>
      <div v-else>暂无数据</div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import TableList from '@/components/TableList'
import Popover from '@/components/Popover'
import timeFormat from '@/utils/timeFormat.js'
import tableObj from '../component/tableData.js'
import { getShardName } from '@/utils/localStorage.js'
import publicFunction from '@/utils/publicFunction.js'
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        // console.log('监听:', this.datas)
        // 清空数据
        this.list = []
        this.pageData.currentPage = 1
        this.getData(1)
      },
      deep: false,
      immediate: true
    }
  },
  components: {
    TableList,
    Popover
  },
  data() {
    return {
      loading: false,
      shardName: getShardName(),
      dialogVisible: false, // 弹窗
      title: '',
      info: '',
      list: [],
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 总条数
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      }, // 分页配置
      columns: [],
      headData: tableObj.headData
    }
  },
  created() {},
  mounted() {
    this.columns = tableObj.tableData
  },
  methods: {
    // 跳转详情页
    handleDetail(publicKey) {
      // console.log('publicKey:', publicKey)
      this.$router.push({
        name: 'accountDetails',
        query: {
          searchKey: publicKey
        }
      })
    },
    // 记账单位数据处理
    getTokens(value) {
      var json = {}
      var _value = JSON.parse(value)
      for (let item in _value) {
        var val = _value[item]
        var key = item.substr(item.length - 11, item.length - 1)
        json['...' + key] = val
      }
      return json
    },
    // 非同质化记账单位数据处理
    getnonToken(value) {
      // var str = value.substr(1, value.length - 2)
      // var _str = str.split(',')
      var array = []
      for (let item of value) {
        var key = item.substr(item.length - 11, item.length - 1)
        array.push('...' + key)
      }
      return array
    },
    // 查看详细信息
    async handleOpen(value, type) {
      // console.log(value, type)
      if (type === 1) {
        this.title = '账户地址'
        // this.info = this.getShowPublicKey(value)
        this.info = value
        this.dialogVisible = true
      } else if (type === 2) {
        this.title = '记账单位'
        this.info = this.getShowTokens(value)
        this.dialogVisible = true
      } else if (type === 3) {
        this.title = '非同质化记账单位'
        this.info = this._syntaxHighlight(value)
        this.dialogVisible = true
      }
    },
    getShowPublicKey(value) {
      if (value.length > 50) {
        return (
          value.substr(0, 29) +
          '...' +
          value.substr(value.length - 31, value.length - 1)
        )
      } else {
        return value
      }
    },
    // tokens弹窗
    getShowTokens(value) {
      var obj = {}
      var _value = JSON.parse(value)
      for (let item in _value) {
        var val = _value[item]
        if (item.length > 65) {
          var key = '......' + item.substr(item.length - 41, item.length - 1)
          obj[key] = val
        } else {
          obj[item] = val
        }
      }
      return this.syntaxHighlight(obj)
    },
    _syntaxHighlight(value) {
      var array = []
      for (let item of value) {
        array.push('<span class="string">' + item + '</span>')
      }
      return array
    },
    syntaxHighlight(_json) {
      var json = {}
      if (typeof _json !== 'string') {
        for (let item in _json) {
          var value = _json[item]
          if (value == null || value === undefined) {
            continue
          }
          json[item] = value
        }
        json = JSON.stringify(json, undefined, 2)
      }
      json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>')
      return json.replace(
        // eslint-disable-next-line no-useless-escape
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
          var cls = 'number'
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = 'key'
            } else {
              cls = 'string'
            }
          } else if (/true|false/.test(match)) {
            cls = 'boolean'
          } else if (/null/.test(match)) {
            cls = 'null'
          }
          return '<span class="' + cls + '">' + match + '</span>'
        }
      )
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    async getData(currentPage) {
      let datas = JSON.parse(JSON.stringify(this.datas))
      if (currentPage === 1) {
        this.loading = true
        let option = {
          page: 0,
          limit: this.pageData.size,
          publicKey: datas.publicKey || null,
          token: datas.token || null,
          type: datas.type || null
        }
        let { data } = await this.$api.chainQL.listAccountStatus(option)
        if (data.listAccountStatus !== null && data.listAccountStatus !== {}) {
          this.pageData.total = data.listAccountStatus.total
          this.list = data.listAccountStatus.accountStatus
        }
      } else if (currentPage > 1) {
        this.loading = true
        let option = {
          page: this.pageData.size * (currentPage - 1),
          limit: this.pageData.size,
          publicKey: datas.publicKey || null,
          token: datas.token || null,
          type: datas.type || null
        }
        let { data } = await this.$api.chainQL.listAccountStatus(option)
        if (data.listAccountStatus !== null && data.listAccountStatus !== {}) {
          this.pageData.total = data.listAccountStatus.total
          this.list = data.listAccountStatus.accountStatus
        }
      }
      this.loading = false
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData(goPage)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData(1)
    },
    // 数字加逗号转化为金额类型
    toThousands(num) {
      return publicFunction.toThousands(num)
    },
    // 一键复制
    copy(e, text) {
      // console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.div-scroll {
  padding: 0;
  height: 200px !important;
  overflow-y: scroll;
}
.dict-value {
  font-weight: bold;
  color: #3f536e;
  margin-bottom: 10px;
}
.table-row:hover {
  text-decoration: underline;
  color: $--color-primary;
}
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.copy-icon {
  position: absolute;
  right: 15px;
}
// js样式
/deep/ pre {
  min-height: 400px;
  background: rgb(230, 230, 230);
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  overflow-x: scroll;
  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
}
/deep/ pre::-webkit-scrollbar {
  height: 10px;
}
.div-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
